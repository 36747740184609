 (function ($) {
	$.menu = function (element, options) {
		// **************************************************************
		// Public Properties
		// **************************************************************

		var $c = $(element), // Context 

			modal = (function ($c, o) {
				var defaults = {
					'activeClass': 'active',

					'speed': 500,
					'easing': 'swing',

					'breakpoint': 900
				}; 

				var _o = {},
					$modal = null
				;

				var _InitObjects = function () {
					_o = $.extend({}, defaults, o);
				},

				_BindEvents = function () {
					_IsOpen();
					_Close();
					_LogoSwap();
					$(window).on('resize', _LogoSwap);
				},

				_LogoSwap = function(e) {
					var width = $(window).width();
					var logo = $('.header-logo');
					if (width > _o.breakpoint) {
						var desktopSrc = logo.attr('dsrc');
						logo.attr('src', desktopSrc);
					}else{
						var mobileSrc = $('.header-logo').attr('msrc');
						logo.attr('src', mobileSrc);
					}
				},

				_IsOpen = function(navOpen) {
					$c.click(function() {
						if (navOpen === 'Open') {
							navOpen = 'Closed';
							_CloseMenu();
						}else{
							navOpen = 'Open';
							_OpenMenu();
						}
					});
				},

				_Close = function() {
					$('.container-m').live('click',function() {
						_CloseMenu();
					});
				},

				_OpenMenu = function(e){
					$('.nav').slideDown(300);
					navOpen = 'Open';
				},

				_CloseMenu = function(e, navOpen){
					$('.nav').slideUp(300);
				},

				init = (function() {
					_InitObjects();
					_BindEvents();
				})();
			}) ($c, options);
	};

	$.fn.menu = function (options) {
		return this.each(function () {
			if (undefined === $(this).data('menu')) {
				var plugin = new $.menu(this, options);
				$(this).data('menu', plugin);
			}
		});
	};
})(jQuery);



